import React from 'react';
import HeroFullRestaurant from '../components/sections/HeroFullRestaurant';
import FeaturesSplitRestaurant from '../components/sections/FeaturesSplitRestaurant';
import FeaturesTilesRestaurant from '../components/sections/FeaturesTilesRestaurant';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';

import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Restaurant extends React.Component {
  state = {
    modalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: false });
  }

  render() {
    const genericSection01Header = {
      title: 'FAQ - Foire aux questions'
    }

    return (
      <React.Fragment>
        <HeroFullRestaurant />
        <Cta secondColor={true} invertColor split title={"Je suis un restaurateur et je souhaite être partenaire ?"} subtitle={"Augmenter votre CA jusqu’à 100 000€ / mois"} labelBtn={"En savoir plus"} link={"/contact"} />
        <FeaturesTilesRestaurant />
        <FeaturesSplitRestaurant invertMobile imageFill topOuterDivider className="illustration-section-05" />
        <Cta invertColor split title={"Vous êtes un artiste, un influenceur ou une marque avec une communauté ?"} subtitle={"Créer le restaurant de votre communauté"} labelBtn={"Plus d'informations"} link={"/createurs"} />
        <GenericSection>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <Accordion>
              <AccordionItem title="Comment faire les plats ?" active>
                Nous vous fournirons les contacts fournisseurs pour les ingrédients de produits alimentaires.
                Vous pourrez aussi utiliser vos propres ingrédients.
                On fera un audit de vos produits.
                Nous vous formerons vous et votre personnel pour certains plats s'il il le faut.
              </AccordionItem>
              <AccordionItem title="Combien cela coûte-t-il ?">
                C’est gratuit :) 
                Pas de frais d’entrée pour nos marques
                Nous gagnons de l'argent quand vous en gagnez, sans frais cachés ni coûts de démarrage.
                Nous proposons un contrat gagnant-gagnant.
              </AccordionItem>
              <AccordionItem title="Quand suis-je payé ?">
                Les paiements sont effectués chaque semaine par virement bancaire.
              </AccordionItem>
              <AccordionItem invertColor={true} title="Si je suis trop occupé, comment on fait ?">
                Aucun problème, vous pouvez activer et désactiver nos marques pour maximiser le rendement de votre cuisine en cas de trop forte demande.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta onClick={this.openModal} invertColor split title={"Avec Gameat la food devient un jeu !"} subtitle={"Le client commande & scan le QR code sur votre emballage pour gagner des points ou lots exceptionnels"} labelBtn={"Essayer un jeu"} />
        <Modal
              id="demo-modal"
              show={this.state.modalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-0 mb-12">Bientôt disponible !</h3>
                <p className="text-sm mb-32">
                  Vous pourrez jouer à différents jeux pour ainsi faire gagner des points ou des lots exceptionnels à vos clients et multiplié à la fois les avis et les récurrences de vos clients.
                </p>
              </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Restaurant;