import React from 'react';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';
import ContactForm from '../components/sections/ContactForm';

import Modal from '../components/elements/Modal';

class Restaurant extends React.Component {
  state = {
    modalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: false });
  }

  Iframe() {
    return (<div className="contact-iframe" dangerouslySetInnerHTML={ {__html: '<iframe width="540" height="1200" src="https://165398c3.sibforms.com/serve/MUIEAE0a3BYOfYC6d20ClwhcMtSwd75d8r80K0cGw4PG19zFuFUS7HteTdMu6d0_EP7ckwDu8dFSWkOg-JTOdSBWUHGPssl0JwX1dlDE9gLI8kF66RRuORfceoH-CEDKKBQvPEU18rRCyN2Pl8ooQep26daU-ptgUBWcKUgjHBbVtnKnvkGPRLQR9JWsqDfx5j7yphxlONW-qL9n" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>'}} />);
  }

  render() {
    const topDivider = false;
    const bottomDivider = false;

    const sectionHeader = {
      title: 'Contactez-nous pour créer le restaurant de demain.',
    };

    const innerClasses = classNames(
      'contact-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <React.Fragment>
        {/* 
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          </div>
    </div> */}
        { this.Iframe() }
        <Cta onClick={this.openModal} invertColor split title={"Avec Gameat la food devient un jeu !"} subtitle={"Le client commande & scan le QR code sur votre emballage pour gagner des points ou lots exceptionnels"} labelBtn={"Essayer un jeu"} />
        <Modal
              id="demo-modal"
              show={this.state.modalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-0 mb-12">Bientôt disponible !</h3>
                <p className="text-sm mb-32">
                  Vous pourrez jouer à différents jeux pour ainsi faire gagner des points ou des lots exceptionnels à vos clients et multiplié à la fois les avis et les récurrences de vos clients.
                </p>
              </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Restaurant;