import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner pb-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="">
          <div className={innerClasses}>
            
            <div className="reveal-from-bottom" data-reveal-delay="600">
            <div className="hero-content container-sm">
              <h1 className="mt-0 mb-12 reveal-from-top" data-reveal-delay="150">
                Créer sa marque de restaurant n'a jamais été aussi simple.
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-top" data-reveal-delay="300">
                Club de foot pro, influenceurs, artistes, nous créons votre marque dans la FOOD.
                </p>
                <div className="reveal-from-top" data-reveal-delay="450">
                  <Button tag="a" color="primary" href="/contact">
                    Suis-je éligible ?
                  </Button>
                </div>
              </div>
            </div>
              <div className="hero-figure-wrapper">
                <Image
                  className="hero-figure"
                  src={require('./../../assets/images/hero-image.png')}
                  alt="Hero"
                />
               </div>
            </div>
            <div className="hero-content container-sm">
              <h3 className="mt-0 mb-32 reveal-from-top" data-reveal-delay="150">
                Ensemble inventons le restaurant pour votre communauté
              </h3>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;