import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Cta from '../components/sections/Cta';

import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Home extends React.Component {
  state = {
    modalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ modalActive: false });
  }

  render() {
    const genericSection01Header = {
      title: 'FAQ - Foire aux questions'
    }

    return (
      <React.Fragment>
        <HeroFull />
        <Cta invertColor split title={"Lancez votre marque !"} subtitle={"Créer le restaurant de votre communauté"} labelBtn={"Contactez-nous"} link={"/contact"} />
        <FeaturesSplit invertMobile imageFill topOuterDivider className="illustration-section-05" />
        <Cta secondColor={true} invertColor split title={"Je suis un restaurateur et je souhaite être partenaire ?"} subtitle={"Augmenter votre CA jusqu’à 100 000€ / mois"} labelBtn={"En savoir plus"} link={"/restaurants"} />
        <GenericSection>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <Accordion>
              <AccordionItem title="Comment créer ma marque dans la food ?" active>
                Premièrement, vous êtes au bon endroit.
                Gameat à été creé pour vous.
                Nous vous aidons à developper votre marque de food à votre image, nous gérons tous les processus, de la creation des menus, du marketing à la mise en vente sur le marché sur les plateformes Uber Eat et Deliveroo.
                Nos offres sont conçu que pour la livraison pour le moment.
                Pour lancer votre marque, contactez- nous : Lien fiche contact.
              </AccordionItem>
              <AccordionItem title="Combien ça coûte ?">
                C’est 100% Gratuit pour les marques, influenceurs et artistes.
                Nous utilisons votre image et votre notoriété et vous utilisez notre expérience et notre réseau pour créer votre marque de food à succès.
                Nous sommes sur de la co-création.
                Un contrat de partenariat Gagnant-Gagnant
              </AccordionItem>
              <AccordionItem title="Combien de temps ça prend pour avoir sa marque en ligne ?">
                Nous lançons votre marque sur le marché sur toute la France en moins de 2 mois.
              </AccordionItem>
              <AccordionItem title="Quelle audience dois-je avoir pour être éligible à l’offre GAMEAT ?">
                Vous devez être une marque, un influenceur, un artiste avec une communauté sur les réseaux sociaux.
              </AccordionItem>
              <AccordionItem title="Quel effort dois-je mettre pour lancer ma marque ?">
                Pas d’effort pour vous, nous savons que votre agenda est plein ;)
                On vous fera des propositions.
                Lancez votre marque pour nous est simple, nous discuterons ensemble juste de l’identité de marque et des recettes.
                Nous gerons tout de A à Z.
              </AccordionItem>
              <AccordionItem title="Quand suis-je payé ?">
                Les paiements sont effectués chaque semaine par Virement bancaire.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta onClick={this.openModal} invertColor split title={"Avec Gameat la food devient un jeu !"} subtitle={"Le client commande & scan le QR code sur votre emballage pour gagner des points ou lots exceptionnels"} labelBtn={"Essayer un jeu"} />
        <Modal
              id="demo-modal"
              show={this.state.modalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-0 mb-12">Bientôt disponible !</h3>
                <p className="text-sm mb-32">
                  Vous pourrez jouer à différents jeux pour ainsi faire gagner des points ou des lots exceptionnels à vos clients et multiplié à la fois les avis et les récurrences de vos clients.
                </p>
              </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Home;